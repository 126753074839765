/*
 * Google Lazyload JS
 * Modified by Harry Bayliss
 */



document.addEventListener("DOMContentLoaded", function () {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy,source.lazy"));

    // Test for if the more performant InteractionObserver is available.
    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    if (lazyImage.dataset.src) lazyImage.src = lazyImage.dataset.src;
                    if (lazyImage.dataset.srcset) lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.classList.remove("lazy");
                    if (lazyImage.parentElement.tagName === 'PICTURE' && lazyImage.parentElement.classList.contains('lazy-wrap')) {
                        for (let i = 0; i < lazyImage.parentElement.children.length; i++) {
                            if (lazyImage.parentElement.children[i].dataset.src) lazyImage.parentElement.children[i].src = lazyImage.parentElement.children[i].dataset.src;
                            if (lazyImage.parentElement.children[i].dataset.srcset) lazyImage.parentElement.children[i].srcset = lazyImage.parentElement.children[i].dataset.srcset;
                            if (lazyImage.parentElement.children[i].classList.contains('lazy')) lazyImage.parentElement.children[i].classList.remove('lazy');
                        }
                        lazyImage.parentElement.classList.remove('lazy-wrap');
                    }
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // If InteractionObserver is not supported, fall back to the old EventListener method.
        let active = false;

        const lazyLoad = function () {
            if (active === false) {
                active = true;

                setTimeout(function () {
                    lazyImages.forEach(function (lazyImage) {
                        if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                            if (lazyImage.dataset.src) lazyImage.src = lazyImage.dataset.src;
                            if (lazyImage.dataset.srcset) lazyImage.srcset = lazyImage.dataset.srcset;
                            lazyImage.classList.remove("lazy");

                            lazyImages = lazyImages.filter(function (image) {
                                return image !== lazyImage;
                            });

                            if (lazyImages.length === 0) {
                                document.removeEventListener("scroll", lazyLoad);
                                window.removeEventListener("resize", lazyLoad);
                                window.removeEventListener("orientationchange", lazyLoad);
                            }
                        }
                    });

                    active = false;
                }, 200);
            }
        };
        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
    }
});